import React from 'react';
import { Flex } from '@unisporkal/alliance-ui-flex-box';
import { useNavLinks } from '../../../../hooks/getty/useNavLinks';
import CartLink from '../CartLink/CartLink';
import NavLink, { NavLinkVariantEnum } from '../NavLink/NavLink';
import EnterpriseDropdown from '../EnterpriseDropdown/EnterpriseDropdown';
import NavBarListItem from '../NavBarListItem/NavBarListItem';
import styles from './MainNavRight.module.scss';

interface MainNavRightProps {
  showEnterpriseDropdown: boolean;
  isVisualGps: boolean;
}

const MainNavRight = ({
  showEnterpriseDropdown,
  isVisualGps,
}: MainNavRightProps) => {
  const links = useNavLinks();

  return (
    <Flex
      as="ul"
      data-testid="RightNavList"
      className={styles.container}
    >
      <NavBarListItem
        dataTestId="enterprise-link"
        hasFullWidthDropdown
      >
        {showEnterpriseDropdown ? (
          (isDropdownOpen, toggleDropdown) => [
            <NavLink
              key="link"
              variant={NavLinkVariantEnum.PILL}
              link={links.enterprise}
              onExpandKeyboardToggle={toggleDropdown}
              isExpanded={isDropdownOpen}
            />,
            <EnterpriseDropdown
              isOpen={isDropdownOpen}
              key="dropdown"
            />,
          ]
        ) : (
          <NavLink
            key="link"
            variant={NavLinkVariantEnum.PILL}
            link={links.enterprise}
          />
        )}
      </NavBarListItem>
      <NavBarListItem dataTestId="pricing">
        <NavLink
          variant={NavLinkVariantEnum.PILL}
          link={links.pricing}
        />
      </NavBarListItem>
      {!isVisualGps ? (
        <NavBarListItem
          className={styles.mobileAndTablet}
          dataTestId="nav-right-cart"
        >
          <CartLink className={styles.cartLink} />
        </NavBarListItem>
      ) : null}
    </Flex>
  );
};

export default MainNavRight;
