export default class CuratedContent {
  siteMap: any;
  searchPathBuilder: any;
  seasonalContent: any;

  constructor(siteMap, searchPathBuilder, seasonalContent) {
    this.siteMap = siteMap;
    this.searchPathBuilder = searchPathBuilder;
    this.seasonalContent = seasonalContent;
  }

  // Seasonal Links are used in the Curated Content Section
  getSeasonalContentLinks(assetCategoryType) {
    if (!this.seasonalContent || !this.seasonalContent[assetCategoryType])
      return [];

    return this.seasonalContent[assetCategoryType]
      .filter((item) => {
        const expirationDate = item.expirationDate
          ? new Date(item.expirationDate)
          : null;
        const startDate = item.startDate ? new Date(item.startDate) : null;
        const afterStartDate = !startDate || startDate <= new Date();
        const beforeExpirationDate =
          !expirationDate || expirationDate > new Date();
        return afterStartDate && beforeExpirationDate;
      })
      .map((item) => ({
        name: item.localizedLabel,
        gaName: item.englishLabel.replace(/\s/g, '_').toLowerCase(),
        href:
          item.localizedPath ||
          this.searchPathBuilder.simpleSearchPath(
            assetCategoryType,
            item.localizedLabel
          ),
      }));
  }
}
