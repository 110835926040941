import React from 'react';
import { useExperience } from '@unisporkal/experiences';
import { useTranslations } from '@unisporkal/localization';
import { Button, ButtonVariantEnum } from '@unisporkal/alliance-ui-button';
import { PANELTYPES } from '../../../../constants';
// eslint-disable-next-line import/no-unresolved
import BoardsIcon from '../icons/Board.svg?icon';
import styles from './Boards.module.scss';

interface BoardsProps {
  openBoardsPanel: (type: PANELTYPES) => void;
  className?: string;
}

const Boards = ({ openBoardsPanel, className = '' }: BoardsProps) => {
  const t = useTranslations();
  const gettySignInEmphasisEnabled = useExperience(
    'GettySignInEmphasis'
  ).isActive();

  const stylesName = gettySignInEmphasisEnabled
    ? styles.pillVariant
    : styles.container;

  const handleBoardsClick = () => {
    openBoardsPanel(PANELTYPES.BOARDS);
  };

  return (
    <Button
      data-testid="nav-boards"
      variant={ButtonVariantEnum.HOLLOW}
      className={`${stylesName} ${className}`}
      onClick={handleBoardsClick}
    >
      <BoardsIcon />
      {t('boards')}
    </Button>
  );
};

export default Boards;
