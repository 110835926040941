import { useTranslations } from '@unisporkal/localization';
import { useCreativeOverviewLinks } from '../useOverviewLinks';

export const useCreativeMusic = () => {
  const trackingId = 'Creative_Music';
  const t = useTranslations();
  const creativeOverviewLinks = useCreativeOverviewLinks();
  const musicTag = 'explore_getty_images_music_copy';

  return {
    trackingId,
    overviewLink: creativeOverviewLinks.creativeMusic,
    overviewText: {
      title: t('music'),
      body: t(musicTag, { music_partner: t('triple_scoop_music') }),
    },
  };
};

export default useCreativeMusic;
