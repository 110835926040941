import React from 'react';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import styles from './MinimalHeader.module.scss';

interface MinimalHeaderProps {
  layout: 'minimal' | 'pop_up';
  linkDisabled?: boolean;
}

const MinimalHeader = ({ layout, linkDisabled }: MinimalHeaderProps) => {
  const containerStyles =
    layout === 'minimal' ? styles.minimalContainer : styles.popupContainer;

  return (
    <header
      data-testid="minimal-header"
      className={containerStyles}
    >
      <HeaderLogo linkDisabled={linkDisabled} />
    </header>
  );
};

export default MinimalHeader;
