import React, { useMemo } from 'react';
import { Flex } from '@unisporkal/alliance-ui-flex-box';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { NavLinkMenuItem } from '../../../../../types/link';
import { useNavLinks } from '../../../../hooks/getty/useNavLinks';
import NavLink, { NavLinkVariantEnum } from '../NavLink/NavLink';
import AIGeneratorIcon from '../icons/AIGenerator.svg?icon';
import NavBarListItem from '../NavBarListItem/NavBarListItem';
import NavMenuDropdown from '../NavMenuDropdown/NavMenuDropdown';
import CreativeMegaNav from '../MegaNavs/CreativeMegaNav/CreativeMegaNav';
import CollectionsMegaNav from '../MegaNavs/CollectionsMegaNav/CollectionsMegaNav';
import EditorialMegaNav from '../MegaNavs/EditorialMegaNav/EditorialMegaNav';
import AIGeneratorMegaNav from '../MegaNavs/AIGeneratorMegaNav/AIGeneratorMegaNav';
import styles from './MainNavLeft.module.scss';

const MainNavLeft = () => {
  const links = useNavLinks();
  const trackNavMenuNavigate =
    (selection: string) => (_evt, link: NavLinkMenuItem) => {
      const ga4Event = navigationEvent({
        link_text: link?.gaName,
        link_url: link?.href,
        navigation_selection: selection,
        ui_element_location: 'header_sub_nav',
      });

      ga4Track(ga4Event);
    };

  const renderAIGeneratorLink = useMemo(
    () => (
      <NavBarListItem
        dataTestId="AIGenerator"
        hasFullWidthDropdown
      >
        {(isDropdownOpen, toggleDropdown) => [
          <NavLink
            key="link"
            variant={NavLinkVariantEnum.PILL}
            link={links.aiGenerator}
            icon={<AIGeneratorIcon className={styles.aiLink} />}
            onExpandKeyboardToggle={toggleDropdown}
            isExpanded={isDropdownOpen}
          />,
          <AIGeneratorMegaNav
            key="dropdown"
            isOpen={isDropdownOpen}
          />,
        ]}
      </NavBarListItem>
    ),
    [links.aiGenerator]
  );

  return (
    <Flex
      as="ul"
      data-testid="LeftNavList"
    >
      <NavBarListItem
        dataTestId="creative-nav-item"
        hasFullWidthDropdown
      >
        {(isDropdownOpen, toggleDropdown) => [
          <NavLink
            key="link"
            variant={NavLinkVariantEnum.PILL}
            link={links.creative}
            onExpandKeyboardToggle={toggleDropdown}
            isExpanded={isDropdownOpen}
          />,
          <CreativeMegaNav
            key="dropdown"
            isOpen={isDropdownOpen}
          />,
        ]}
      </NavBarListItem>
      <NavBarListItem
        dataTestId="editorial-nav-item"
        hasFullWidthDropdown
      >
        {(isDropdownOpen, toggleDropdown) => [
          <NavLink
            key="link"
            variant={NavLinkVariantEnum.PILL}
            link={links.editorial}
            onExpandKeyboardToggle={toggleDropdown}
            isExpanded={isDropdownOpen}
          />,
          <EditorialMegaNav
            key="dropdown"
            isOpen={isDropdownOpen}
          />,
        ]}
      </NavBarListItem>
      <NavBarListItem dataTestId="video-nav-item">
        {(isDropdownOpen, toggleDropdown) => [
          <NavLink
            key="link"
            variant={NavLinkVariantEnum.PILL}
            link={links.video}
            onExpandKeyboardToggle={toggleDropdown}
            isExpanded={isDropdownOpen}
          />,
          <NavMenuDropdown
            key="dropdown"
            menuItems={[links.creativeVideo, links.editorialVideo]}
            onNavigate={trackNavMenuNavigate('video')}
          />,
        ]}
      </NavBarListItem>
      <NavBarListItem
        dataTestId="collections-nav-item"
        hasFullWidthDropdown
      >
        {(isDropdownOpen, toggleDropdown) => [
          <NavLink
            key="link"
            variant={NavLinkVariantEnum.PILL}
            link={links.collections}
            onExpandKeyboardToggle={toggleDropdown}
            isExpanded={isDropdownOpen}
          />,
          <CollectionsMegaNav
            key="dropdown"
            isOpen={isDropdownOpen}
          />,
        ]}
      </NavBarListItem>
      {renderAIGeneratorLink}
      <NavBarListItem dataTestId="insights-nav-item">
        <NavLink
          variant={NavLinkVariantEnum.PILL}
          link={links.visualGps}
        />
      </NavBarListItem>
    </Flex>
  );
};

export default MainNavLeft;
