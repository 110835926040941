import React from 'react';

import NotificationsList from '../shared/NotificationsList/NotificationsList';
import MinimalHeader from './MinimalHeader/MinimalHeader';
import VisualGpsHeader from './VisualGpsHeader/VisualGpsHeader';
import FullHeader from './FullHeader/FullHeader';
import styles from './Header.module.scss';

interface HeaderProps {
  currentPage: string;
  layout: string;
  linkDisabled?: boolean;
  showEnterpriseDropdown?: boolean;
}

const Header = ({
  currentPage,
  layout,
  linkDisabled = false,
  showEnterpriseDropdown = true,
}: HeaderProps) => {
  const headerLayout = () => {
    switch (layout) {
      case 'minimal':
      case 'pop_up':
        return (
          <MinimalHeader
            layout={layout}
            linkDisabled={linkDisabled}
          />
        );
      case 'visual_gps':
        return <VisualGpsHeader />;
      default:
        return <FullHeader showEnterpriseDropdown={showEnterpriseDropdown} />;
    }
  };

  return (
    <div className={styles.container}>
      <NotificationsList currentPage={currentPage} />
      {headerLayout()}
    </div>
  );
};

export default Header;
